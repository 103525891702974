/**
 * Manager to handle the fetching and storage of 'Here' Geocaching data
 * takes an address, returns a queried address containing Geo Coordinates
 * 
 * This will be stored in cache for future use, and reduction of requests to Here
 * 
 *      free limit is 30,000 monthly requests
 * 
 * API docs: https://developer.here.com/documentation/geocoding-search-api/api-reference-swagger.html
 *  sample urls:
 * 
 * 
 * // docs: https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics-api/code-geocode-address.html
https://geocode.search.hereapi.com/v1/geocode
    ?q=240+Washington+St.%2C+Boston
    &apiKey={YOUR_API_KEY}
  

    // docs: https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics-api/code-geocode-hpc-hsn.html
https://geocode.search.hereapi.com/v1/geocode
    ?qq=
        houseNumber=425;
        postalCode=60606-1506
    &apiKey={YOUR_API_KEY}
 * 
 */

// NOTE: if this throws an import error, add "browser": { "fs": false } to 'package.json' (webpack bug)
// import FILE_SYSTEM from 'fs'
// const FILESYS = require('fs')
import Axios from "axios"
import { isObject } from "~/UTILS/object_utils"
// import GEOCODER from 'node-geocoder'

// import * as FILESYS from 'fs'

// window.FILESYS = FILESYS

/* 

    // here's how it's initialized in node-geocoder
    if (geocoderName === 'here') {
      return new HereGeocoder(adapter, {
        apiKey: extra.apiKey,
        appId: extra.appId,
        appCode: extra.appCode,
        language: extra.language,
        politicalView: extra.politicalView,
        country: extra.country,
        state: extra.state,
        production: extra.production,
        limit: extra.limit
      });
    }


    // sample query to here
    https://geocode.search.hereapi.com/v1/
    geocode
    ?q=Invalidenstr+117+Berlin
    &apiKey={YOUR_API_KEY}

    so 
    `https://geocode.search.hereapi.com/v1/geocode?q=${ADDR_STRING}&apiKey=${HERE_API_STRING}`

    or more refined can be found here:
    https://developer.here.com/documentation/geocoding-search-api/api-reference-swagger.html



*/

class MANAGER {
    OBJECTS = {}

    constructor({ api_key, initial_data = [] }) {
        console.log("created a HereAPI manager")
        // console.log(`API KEY: ${api_key}`)

        this._api_key = api_key

        this._DATA = initial_data
        this._baseURL = "https://geocode.search.hereapi.com/v1/geocode"
    }

    _generateURL(queryParams = {}) {
        let querySTR = Object.entries(queryParams)
            .map(([key, value]) => {
                let subquery
                if (isObject(value)) {
                    let subquery = Object.entries(value).map(
                        ([subkey, subvalue]) => `${subkey}=${subvalue}`
                    )
                    return `${key}=${subquery.join(";")}`
                }

                return `${key}=${value}`
            })
            .join("&")

        return `${this._baseURL}?${querySTR}`
    }

    async _fetchData(requestURL) {
        // console.log("requesting: ", requestURL)

        try {
            let response = await Axios.get(requestURL)

            // just return the first result
            let returnData = {
                requestURL,
                ...response.data.items[0],
            }
            return returnData
        } catch (error) {
            console.log(error)
            return {
                requestURL,
                error,
            }
        }
    }

    async syncWithNewData(newData = []) {
        // format new data into querystrings
        let formattedQueryStrings = newData.map((datum) => {
            return this._generateURL({
                q: encodeURIComponent(datum.address1),
                qq: {
                    city: encodeURIComponent(datum.city),
                    state: encodeURIComponent(datum.state),
                    postalCode: encodeURIComponent(datum.zip),
                },
                apiKey: this._api_key,
            })
        })

        // console.log("querystrings: ", formattedQueryStrings)

        let new_data = []
        for (const request of formattedQueryStrings) {
            let request_result = await this._fetchData(request)

            new_data.push(request_result)
        }

        // console.log("new data: ", newData)
        this._DATA.push(...new_data)
    }

    get getData() {
        return this._DATA
    }

    get numEntries() {
        return this._DATA.length
    }
}

export default MANAGER
