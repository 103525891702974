/**
 * helper methods to simplify basic actions performed against the dom
 */


/**
 * Replacement for the jQuery '$(document).ready(...)' functionality
 * @param {any} callback_fn function to call when the document is ready
 */
export function Document_Ready(callback_fn) {
    if (document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
        callback_fn.call();
    } else {
        document.addEventListener("DOMContentLoaded", callback_fn);
    }
}

/**
 * querySelector wrapper
 * @param {*} selector selector string
 * @param {*} parent HTML element (defaults to document)
 * @returns matching element
 */
export function select(selector, parent = document){
    return parent.querySelector(selector)
}

/**
 * querySelectorAll wrapper, except returns array instead of domList
 * to allow for array methods such as forEach
 * @param {*} selector selector string
 * @param {*} parent HTML element (defaults to document)
 * @returns Array of matching elements
 */
export function selectAll(selector, parent = document){
    return [...parent.querySelectorAll(selector)]
}

/**
 * wrapper for document.createElement to specify classes/attributes/text/etc
 * in a config object 
 * @param {*} type element to be created, i.e. 'div', 'span', etc.
 * @param {*} options object configuration. specify 'class' (string or string[]), 'data' (obj mapping 'data-*' attrs), 'text', anything else is treated as a generic attrubute to be added
 */
export function createElement(type, options={}){
    
    let el = document.createElement(type)
    updateElement(el,options)
    return el
}

/**
 * update an existing element with specified config options 
 * @param {HTMLElement|HTMLElement[]} el accepts HTMLEntity or Array of HTMLEntities to be updated, param is  PASS-BY-REFERENCE so the entity itself is modified
 * @param {*} options object configuration. specify 'class' (string or string[]), 'data' (obj mapping 'data-*' attrs), 'text', anything else is treated as a generic attrubute to be added
 * @returns 
 */
export function updateElement(elements, options={}){
    
    // ensure passed the correct type
    if(!(elements instanceof HTMLElement || elements instanceof Array)) throw new Error('DOM.updateElement: Invalid type passed to updateElement! Expected HTMLEntity or HTMLEntity[]')

    let collection = (elements instanceof Array)? elements: [elements];
    


    collection.forEach(el => {
        // check each individual element passed for type
        if(!(el instanceof HTMLElement)) throw new Error('DOM.updateElement: Invalid type passed to updateElement! One or more passed elements are not of type <HTMLEntity>')
        
        Object.entries(options).forEach(([key,value])=> {
            // add single or array of classes
            if(key === 'class'){

                if(Array.isArray(value)){
                    el.classList.add(...value)
                }else{
                    el.classList.add(value)
                }
                return
            }

            // add dataset
            if(key === 'data'){
                Object.entries(value).forEach(([dataKey, dataValue])=> {
                    // this throws weird errors with hyphenated attrs so do it the hacky way
                    //el.dataset[dataKey] = dataValue
                    if(dataKey.includes('-'))
                        el.setAttribute(`data-${dataKey}`,dataValue)
                    else
                        el.dataset[dataKey] = dataValue

                })
                return
            }

            // add text
            if (key === 'text'){
                el.textContent = value
                return
            }

            // set data attribute as default
            el.setAttribute(key,value)

        })
        return el

    })
    
}

export default {
    Document_Ready,
    select,
    selectAll,
    createElement,
    updateElement,
}