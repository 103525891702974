﻿/**
 * Module to handle handle interactions with the Layer/Feature Filtering
 *
 * creates an object for each 'Filter' and allows for enabling/disabling display by passing events back to a listner
 *
 */

import DOM from "~/UTILS/dom"

import FILTER from "~/components/Filter"
import CUSTOM_FEATURE_FILTERS from "./MapFeatureFilters"

const MANAGER = {
    OBJECTS: {
        Filters: CUSTOM_FEATURE_FILTERS
        // StateFilter,
        // DateRangeFilter,
    },

    init(config = {}) {
        let requiredConfigs = ["target"]

        if (requiredConfigs.some((key) => !Object.keys(config).includes(key))) {
            console.error(
                `Filter_Manager.init: The passed config is missing a required value! Config must contain values for the following: ${requiredConfigs.join(
                    ", "
                )}`
            )
            return
        }

        
        this._filters = []
        this._controls = {}
        // this._filters = {}
        this._target = config.target

        // grab the target container from the dom
        this.$element = DOM.select(`#${this._target}`)
        this.$element.classList.add("filters_container")

        this._generateDOM()

    },

    get element() {
        return this.$element
    },

    _generateControlsWrapper(filterGroup) {
        let controls = DOM.createElement("div", {
            class: ["controls_container"],            
            data: {
                filterGroup
            }  
        })

        return controls
    },

    _generateFiltersWrapper(filterGroup) {
        let filters = DOM.createElement("div", {
            class: ["filters"],       
        })

        return filters
    },

    _generateFilterGroupWrapper(groupName){
        let filters = DOM.createElement("div", {
            class: ["filterGroup"],       
            data: {
                filterGroup: groupName
            }     
        })

        // let groupControls = this._generateControlsWrapper(filterGroup)
        // filters.append(groupControls)

        return filters
    },

    _generateDOM() {

        this.$filtersWrapper = this._generateFiltersWrapper()
        this.element.append(this.$filtersWrapper)
    },
    

    addControl(config){
        
        let {group, element} = config
        let groupWrapper = (typeof(group)=="undefined") ?
            this.$filtersWrapper :
            // this.$filtersWrapper.querySelector(`.filterGroup[data-filter-group="${group}"]`)
            this.getGroup(group)

        // if the filter group doesn't exist we cant add the control
        if(!groupWrapper){
            console.warn(`FILTER_MANAGER.addControl: could not add control to non existant filter group '${group}'`)
            return
        }

        let controlsWrapper = groupWrapper.querySelector(`.controls_container[data-filter-group="${group}"]`)
        if(!controlsWrapper) {
            controlsWrapper = this._generateControlsWrapper(group)
            groupWrapper.prepend(controlsWrapper)
        }

        controlsWrapper.append(element)

    },


    addFilter(new_filter) {

        if(!(new_filter instanceof FILTER.FilterObj)) {
            console.warn("MapFeatureFiltersManager.addFilter: Filters must be derived from class 'components/Filter.FilterObj'!")
            return
        }
        // let new_filter = new StateFilter(config)
        this._filters.push(new_filter)

        // if this filter group doesn't exist add a new wrapper
        // let groupWrapper = this.$filtersWrapper.querySelector(`.filterGroup[data-filter-group="${new_filter.group}"]`)
        let groupWrapper = this.getGroup(new_filter.group)
        if(!groupWrapper){
            groupWrapper = this._generateFilterGroupWrapper(new_filter.group)
            this.$filtersWrapper.append(groupWrapper)
        }

        groupWrapper.append(new_filter.element)

        
    },

    getGroup(groupName){
        return this.$filtersWrapper.querySelector(`.filterGroup[data-filter-group="${groupName}"]`)
    },

    getGroupFilters(groupName){
        return this._filters.filter((f) => f.group == groupName)
    },

    getFilter(filterID) {
        
        // console.log('getting filter: ', filterID)
        return this._filters.find((filter) => filter._id == filterID)
    },
}

export default MANAGER
