﻿import { DeepMerge } from "~/UTILS/object_utils"

export class FeatureState {
    constructor({ id, label, style, priority = 0 }) {
        this._id = id
        this._label = label
        this._style = style

        // THIS acts as a sorting index, higher number sorted first
        this._priority = priority
    }

    getID() {
        return this._id
    }

    getStyle(...args) {
        if (args.length > 2)
            throw Error(
                `FeatureState.getStyle: getStyle method expects at most 2 arguments. An optional 'Style name' string matching configuration, and a 'style overwrite' for one time style modifications`
            )

        if (typeof args[0] == "string") {
            return this._getStyle({ styleName: args[0], overwrites: args[1] })
        } else {
            if (this._style.default) {
                return this._getStyle({
                    styleName: "default",
                    overwrites: args[0],
                })
            } else {
                return this._getStyle({ overwrites: args[0] })
            }
        }
    }

    _getStyle({ styleName, overwrites = {} }) {
        // console.log(
        //     `returning style '${styleName}' with overwrites: `,
        //     overwrites
        // )

        if (styleName) {
            return DeepMerge(this._style[styleName], overwrites)
        } else {
            return DeepMerge(this._style, overwrites)
        }
    }

    getPriority() {
        return this._priority
    }

    getLabel() {
        return this._label
    }
}

let _states = []
const MANAGER = {
    OBJECTS: {
        FeatureState,
    },

    addState(state) {
        if (!(state instanceof FeatureState)) {
            console.warn(
                'FeatureState_Manager: attempted to add an invalid state. Must be an instance of "FeatureState"!'
            )
            return
        }

        if (_states.filter((s) => s.getID() == state.getID()).length) {
            console.warn(
                `FeatureState_Manager: attempted to add a state with a duplicate ID "${_states._id}". ID must be an unique!`
            )
            return
        }

        _states.push(state)
    },

    getState(id) {
        return _states.find((s) => s._id == id)
    },

    getStates() {
        return _states
    },

    sortKeysByPriority(states_to_sort) {
        return states_to_sort.sort((a, b) => {
            //console.log(b)
            //console.log(this.getState(b))
            //console.log(this.getStates())

            return this.getState(b) == undefined ||
                this.getState(a) == undefined
                ? 0
                : this.getState(b).getPriority() -
                      this.getState(a).getPriority()
        })

        //const sortable = Object.fromEntries(
        //    Object.entries(maxSpeed).sort(([, a], [, b]) => b.getPriority() - a.getPriority())
        //);
    },

    highestPriority(stateIDs) {
        if(!stateIDs.length) return []
        
        return stateIDs
            .map((state) => this.getState(state))
            .reduce((prev, current) => {
                return prev.getPriority() > current.getPriority()
                    ? prev
                    : current
            })
            .getID()
    },
}

export default MANAGER
