﻿/**
 * merges 2 objects into a single object (deeply)
 * @param {Object} obj1 
 * @param {Object} obj2 
 * @returns Object
 */
export function DeepMerge(obj1 = {}, obj2 = {}) {
    // Create a new object that combines the properties of both input objects
    const merged = {
        ...obj1,
        ...obj2
    };

    // Loop through the properties of the merged object
    for (const key of Object.keys(merged)) {
        // Check if the property is an object
        if (typeof merged[key] === 'object' && merged[key] !== null) {
            // If the property is an object, recursively merge the objects
            merged[key] = DeepMerge(obj1[key], obj2[key]);
        }
    }

    return merged;
}

export function isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}

/**
 * returns boolean representing of the passed arrays contain the same values in the same order
 * @param {Array} a 
 * @param {Array} b 
 * @returns boolean
 */
export function ArraysEqual(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}




/**
 * returns an object mapping the 'groupByValue' to a collection of objects matching that value
 * 
 * @param {[Object]]} collection Array of objects to group
 * @param {String|Function} groupByValue allows for a key string, or an evaluation function that returns a value to sort by (allowing for deep dive into the element)
 *  e.g. GroupBy([...objects...], (elem)=>elem.child.child2value)
 * @returns an object mapping the value of 'groupByValue' as key to a collection of objects with that value
 */
export function  GroupBy(collection, groupByValue){
    // allow callback evaluations as groupby fields
    

    return collection.reduce((group, elem) => {
        let groupByResult = (typeof(groupByValue) == "string")? elem[groupByValue]: groupByValue.apply(elem, [elem])
        const category = groupByResult;
        group[category] = group[category] ?? [];
        group[category].push(elem);
        return group;
      }, {});
} 