import DOM from "~/UTILS/dom"
import FILTERS from "~/components/Filter"
import flatpickr from "flatpickr";
import "./MapFeatureFilters.scss"

// import datepicker from 'js-datepicker'
class StateFilter extends FILTERS.FilterObj{
    constructor(config) {

        // specify extra filter config params for internal storage
        let {icon} = config
        

        super({
            icon,
            
            
            ...config
        })

        this._icon = icon
        
        //let requiredConfigs = ['id']

        //if (requiredConfigs.some(key => !Object.keys(config).includes(key))) {
        //    console.error(`Filter: The passed config is missing a required value! Config must contain values for the following: ${requiredConfigs.join(', ')}`)
        //    return;
        //}

        
        this._updateBodyDOM()
    }

    _updateBodyDOM() {
        
        if (this._icon) {
            this.$body.append(
                DOM.createElement("img", {
                    class: ["filter_icon"],
                    src: this._icon,
                })
            )
        }

        this.$body.append(
            DOM.createElement("div", {
                class: ["filter_name"],
                text: this._name,
            })
        )

        
    }

    
    
}

class DateRangeFilter extends FILTERS.FilterObj {
    constructor(config){
        let enabled = config.enabled || false
        let name = config.enabled || false
        super({enabled, ...config})

        this._minDate = config.minDate
        this._maxDate = config.maxDate
        this._onValueChange = config.onValueChange
        
        this._updateBodyDOM()
    }

    _updateBodyDOM() {

        let container = DOM.createElement('div', {
            class: ['filter_datepicker']
        })

        let label =  DOM.createElement('div', {            
            // class: ['filter_start'],
            text:'Filter by Service Date'
        })
        let dateRange_wrapper = DOM.createElement('div', {
            class: ['dateRange_wrapper']
        })
        
        this.$dateRangeInput = DOM.createElement('input', {
            class: ['filter_range'],
        })

        let helpText =  DOM.createElement('div', {            
            class: ['filter_help'],
            text:'Select a date range to filter by. To select a single date, double click it.'
        })

        dateRange_wrapper.append(this.$dateRangeInput)
        dateRange_wrapper.append(helpText)

        
        this.$picker = flatpickr(this.$dateRangeInput, {
            mode: "range",
            dateFormat: "m/d/Y",
            inline: true,

            minDate: this._minDate,
            maxDate: this._maxDate,

            onChange: this._onValueChange


        }),

        container.append(label)
        container.append(dateRange_wrapper)
        // container.append(end_wrapper)

        this.$body.append(container)
    }

    get value(){
        return this.$picker.selectedDates
    }
    

    
}



export default {
    DateRangeFilter,
    StateFilter,
}