if(
    typeof(process.env.HERE_API_KEY) == "undefined"
){
  console.error(`Here API missing required environment definitions! 
    The following env variables are required: 
        HERE_API_KEY`)  
}

export default {
    api_key: process.env.HERE_API_KEY,       
    
}