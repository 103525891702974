/**
 * Middleware component for handling 'sweetalert2' modals in the application
 * provides methods of defining reusable config types
 */

import SWEET_ALERT from "sweetalert2"

// default modal styling
import "sweetalert2/src/sweetalert2.scss"

// import overrides
import "./Modal.scss"

// define default 'sweetalert2' configuration types
export let TYPES = {
    DEFAULT: {
        customClass: "CUSTOM_MODAL",
        width: "70vw",
        // heightAuto: false,
    },
}

export function UpdateTypes(key, config, extend_default = true) {
    TYPES[key] = { ...(extend_default ? TYPES.DEFAULT : {}), ...config }
}

function ShowModal({ type = "DEFAULT", overrides = {} } = {}, callback_fn) {
    if (Object.keys(TYPES).indexOf(type) == -1) {
        console.warn(`
        Notifications: Attempted to show a notification of undefined type: '${type}', reverting to default notification styles
            to define this type import 'TYPES' and define it with  TYPES.update(name, {...})
    `)
        type = "DEFAULT"
    }

    let config = {
        ...TYPES[type],
        ...overrides,
    }

    SWEET_ALERT.fire(config).then(callback_fn)
}

export default { UpdateTypes, ShowModal }
