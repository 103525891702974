import { FeatureState } from "~/components/Map/MapFeatureStates"

let FEATURE_LABEL_STYLING = {
    //text: text,
    textAlign: "left",
    //textBaseline: baseline,
    font: "bold 13px Calibri,sans-serif",
    fill: {
        color: "#000",
    },
    stroke: {
        color: "#ffffff",
        width: 5,
    },
    offsetX: 20,
    offsetY: -10,
    placement: "point",
    maxAngle: 45,
    //overflow: ,
    rotation: 0,
}

/******************************************************
 *  FEATURE STATE CONFIGURATIONS
 *  
Debris removal
Distribution, cleaning supplies
Distribution, water
Feeding, cold meals
Feeding, hot meals
Housing, apartment
Housing, hotel
Housing, mass shelter
Housing, trailer
Housing, other
Mucking House
Tarping Roof
Transportation
 ******************************************************/
let FILTERABLE_STATES = {
    "Debris removal": new FeatureState({
        id: "Debris removal",
        label: "Debris removal",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/broom-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Distribution, cleaning supplies": new FeatureState({
        id: "Distribution, cleaning supplies",
        label: "Distribution, cleaning supplies",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/parachute-box-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Distribution, water": new FeatureState({
        id: "Distribution, water",
        label: "Distribution, water",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/parachute-box-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Feeding, cold meals": new FeatureState({
        id: "Feeding, cold meals",
        label: "Feeding, cold meals",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/utensils.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Feeding, hot meals": new FeatureState({
        id: "Feeding, hot meals",
        label: "Feeding, hot meals",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/utensils.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Housing, apartment": new FeatureState({
        id: "Housing, apartment",
        label: "Housing, apartment",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/house-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Housing, hotel": new FeatureState({
        id: "Housing, hotel",
        label: "Housing, hotel",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/house-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Housing, mass shelter": new FeatureState({
        id: "Housing, mass shelter",
        label: "Housing, mass shelter",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/house-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Housing, trailer": new FeatureState({
        id: "Housing, trailer",
        label: "Housing, trailer",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/house-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Housing, other": new FeatureState({
        id: "Housing, other",
        label: "Housing, other",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/house-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Mucking House": new FeatureState({
        id: "Mucking House",
        label: "Mucking House",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/broom-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    "Tarping Roof": new FeatureState({
        id: "Tarping Roof",
        label: "Tarping Roof",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/house-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
    Transportation: new FeatureState({
        id: "Transportation",
        label: "Transportation",
        priority: 1,
        style: {
            default: {
                icon: {
                    src: "assets/svgs/bus-solid.svg",
                    color: "#fff",
                    scale: 1,
                    displacement: [0, 0],
                },
            },
        },
    }),
}

let ADDITIONAL_STATES = {
    DEFAULT_FEATURE: new FeatureState({
        id: "DEFAULT_FEATURE",
        priority: 100,

        style: {
            
            text: {
                ...FEATURE_LABEL_STYLING,

                fill: { color: "#0066ff" },
                stroke: { color: "#ffffff", width: 5 },
            },

            circle: {
                radius: 16,

                fill: {
                    color: "#0066ff",
                },
                stroke: {
                    color: "#0066ff",
                    width: 2,
                },
            },
        },
    }),

    // SEVERITY_MINOR: new FeatureState({
    //     id: "SEVERITY_MINOR",
    //     priority: 101,
    //     style: {
    //         text: {
    //             ...FEATURE_LABEL_STYLING,
    //             fill: { color: "#008833" },
    //             stroke: { color: "#ffffff", width: 5 },
    //         },

    //         circle: {
    //             radius: 16,

    //             fill: {
    //                 color: "#008833",
    //             },
    //             stroke: {
    //                 color: "#008833",
    //                 width: 2,
    //             },
    //         },
    //     },
    // }),

    // SEVERITY_MODERATE: new FeatureState({
    //     id: "SEVERITY_MODERATE",
    //     priority: 102,
    //     style: {
    //         text: {
    //             ...FEATURE_LABEL_STYLING,
    //             fill: { color: "#fced19" },
    //             stroke: { color: "#ffffff", width: 5 },
    //         },

    //         circle: {
    //             radius: 16,

    //             fill: {
    //                 color: "#fced19",
    //             },
    //             stroke: {
    //                 color: "#fced19",
    //                 width: 2,
    //             },
    //         },
    //     },
    // }),

    // SEVERITY_URGENT: new FeatureState({
    //     id: "SEVERITY_URGENT",
    //     priority: 103,
    //     style: {
    //         text: {
    //             ...FEATURE_LABEL_STYLING,
    //             fill: { color: "#fa9a39" },
    //             stroke: { color: "#ffffff", width: 5 },
    //         },

    //         circle: {
    //             radius: 16,

    //             fill: {
    //                 color: "#fa9a39",
    //             },
    //             stroke: {
    //                 color: "#fa9a39",
    //                 width: 2,
    //             },
    //         },
    //     },
    // }),

    // SEVERITY_EMERGENCY: new FeatureState({
    //     id: "SEVERITY_EMERGENCY",
    //     priority: 999,
    //     style: {
    //         text: {
    //             ...FEATURE_LABEL_STYLING,
    //             fill: { color: "#ff0000" },
    //             stroke: { color: "#ffffff", width: 5 },
    //         },

    //         circle: {
    //             radius: 16,

    //             fill: {
    //                 color: "#ff0000",
    //             },
    //             stroke: {
    //                 color: "#ff0000",
    //                 width: 2,
    //             },
    //         },
    //     },
    // }),
}

export default {
    FILTERABLE: [...Object.keys(FILTERABLE_STATES)],
    STATES: {
        ...FILTERABLE_STATES,
        ...ADDITIONAL_STATES,
    },
}
