﻿"use strict"
/**
 * NOTE: During the transition phase, much of this code is directly copied from an old project
 *      so expect revisions to the workflow
 */

/*
 
 Expected endpoints:
    add layer from feature list
    get various Openlayer instances directly
        - map, layers, features, interactions
    refresh map
    toggle visibility

    

most likely need object types for normalized/simplified interactions
    layer
    feature

 
 */

import "ol/ol.css"

import OL_Map from "ol/Map"
import OL_View from "ol/View"
import OL_Feature from "ol/Feature"

import VectorLayer from "ol/layer/Vector"
import TileLayer from "ol/layer/Tile"

import { fromLonLat } from "ol/proj"

import OSM_Source from "ol/source/OSM"
import Vector_Source from "ol/source/Vector"
//import XYZ_Source from "ol/source/XYZ"
//import { GeoJSON } from "ol/format"
//import { transformExtent } from "ol/proj"

import { Style, Stroke, Fill, Circle, RegularShape, Icon, Text } from "ol/style"

import {
    Select,
    // DragRotateAndZoom,
    defaults as DEFAULT_INTERACTIONS,
} from "ol/interaction"

import { click } from "ol/events/condition"

import { FullScreen, defaults as DEFAULT_CONTROLS } from "ol/control.js"

import Color from "color"
import { Point } from "ol/geom"

import { isObject } from "~/UTILS/object_utils"

class LayerConfig {
    constructor({ name = "unnamed layer", id, coords }) {
        this._coords = coords
        this._name = name
        this._id = id
    }

    coords() {
        return this.coords
    }
    name() {
        return this._name
    }
    id() {
        return this._id
    }
}

/**
 * singleton object for handling map actions
 */
const MANAGER = {
    OBJECTS: {
        Layer: LayerConfig,
    },

    props: {
        ZOOM_EXTENT_BUFFER: 2000,
        //DATE_FORMAT: "M/D/YY h:mm a",
        //DEFAULT_COORDS: ["-98.585522", "39.8333333"], // US Center
        DEFAULT_COORDS: ["-91.962334", "30.984299"], // Louisiana Center
        DEFAULT_ZOOM: 7,
        DEFAULT_PROJECTION: "EPSG:3857",
        DEFAULT_BBOX: [
            -23497932.822872348, 233516.64360559173, 1548952.6056142095,
            9449987.766119003,
        ],
        DEFAULT_EXTENT_LA: [
            -11019915.362681154, 3308757.474346672, -9454485.023400746,
            3952663.0006209966,
        ],
        // DEFAULT_BBOX: [ -23497932.82, 233516.64360, 1548952.6056, 9449987.766 ],
        DEFAULT_LAYER_COLOR: "#0099ff",
        //ALT_LAYER_COLORS: [
        //    "#D47829", // orange
        //    "#0cdd5A", // light green
        //    "#A62611", // red
        //    "#1F6586", // dark blue
        //    "#42855F", // dark green
        //],

        // map 'interface layer types' to OL recognized constructors
        LAYER_TYPE_MAPPING: {
            geojson: VectorLayer,
            //kml: VectorLayer,
            local_geojson: VectorLayer,
            //tile: TileLayer,
        },

        LAYER_TYPE_SOURCES: {
            geojson: Vector_Source,
            //kml: Vector_Source,
            local_geojson: Vector_Source,
            //tile: Tile_Source,
        },
        LAYER_DATA_FORMATS: {
            //kml: KML,
        },

        DEFAULT_STYLING_CONFIG: {
            point: {
                point_type: "circle", // "circle" || "triangle" || "square" || "star"

                color: "#0f0",
                opacity: 0.2,
                width: 2,

                fill_color: null, // falls back to 'color' attribute
            },
            //line: {
            //    color: "#0f0",
            //    opacity: 0.2,
            //    width: 2,
            //},
            //polygon: {
            //    color: "#0f0",
            //    opacity: 0.2,
            //    width: 2, // outline width

            //    pattern_type: "none", // 'none' || 'line' || 'dash' || 'pinstripe'
            //    pattern_size: 20, // size of the pattern tiles (square)

            //    // lines drawn on the fill layer (not outline)
            //    stroke_width: 2,
            //    stroke_opacity: 0.5,

            //    fill: true, // transparent color backdrop in polygon
            //    fill_color: null, // falls back to 'color' attribute
            //    fill_opacity: null, // falls back to 'opacity' attribute
            //},
        },
    },

    init(config) {
        this._config = config
        this._interactions = {} // map layers to interactions
        this._controls = {} // map layers to interactions
        this._base_layers = []
        this._registered_layers = []

        this._featureStates = []

        // need to set a default layer style from config

        this.generateBaseLayers()
        this.createMap(config.target)
    },
    generateBaseLayers() {
        this._base_layers = [
            {
                name: "base_street",
                ol_layer: new TileLayer({
                    name: "base_street",
                    source: new OSM_Source(),
                }),
            },
        ]

        // set the first to visible by default
        this._base_layers.forEach((layer, index) =>
            layer.ol_layer.setVisible(index == 0 ? true : false)
        )
    },

    /**
     * Create an openlayers map instance in the specified id
     * @param {any} containerID
     */
    createMap(containerID) {
        this._controls["fullscreen"] = new FullScreen()

        this._interactions["select"] = new Select({ condition: click })
        this._interactions["select"].on("select", this._config.onSelect)

        // OpenLayers map
        this.map = new OL_Map({
            target: containerID,
            layers: this._base_layers.map((layer) => layer.ol_layer),
            view: new OL_View({
                center: fromLonLat(this.props.DEFAULT_COORDS),
                // bbox: this.props.DEFAULT_BBOX,
                zoom: this.props.DEFAULT_ZOOM,
                extent: this.props.DEFAULT_EXTENT_LA, // limit inspectable area to around louisiana
            }),

            controls: DEFAULT_CONTROLS({ rotate: false }).extend(
                Object.values(this._controls)
            ),

            interactions: DEFAULT_INTERACTIONS({
                altShiftDragRotate: false,
                pinchRotate: false,
            }).extend(Object.values(this._interactions)),
        })
    },

    resetView() {
        this.map.getView().animate({
            zoom: this.props.DEFAULT_ZOOM,
            center: fromLonLat(this.props.DEFAULT_COORDS),
        })
    },

    /**
     * Create and add a map layer from a collection of features
     * @param {string} layerID
     * @param {object} features objects containing 'coords' and 'states'
     */
    addLayer(layerID, features) {
        let layerSource = new Vector_Source()
        let layer = new VectorLayer({
            source: layerSource,
            visible: true,
        })

        let REGISTRATION = {
            id: layerID,
            features: [],
            ol_source: layerSource,
            ol_layer: layer,
        }

        // the 'feature styling' method should be passed in
        features.forEach((feature, index) => {
            let point = new Point(fromLonLat(feature.coords))

            let new_feature = new OL_Feature({
                id: feature.name,
                geometry: point,
                name: feature.name,
            })

            REGISTRATION.ol_source.addFeature(new_feature)

            REGISTRATION.features.push({
                ref: feature,
                ol_feature: new_feature,
            })
        })

        this._registered_layers.push(REGISTRATION)

        this.map.addLayer(REGISTRATION.ol_layer)
    },

    /**
     * Get a specific layer from the Manager, returns an object containing both the OL Layer, 'ol_layer', and OL Source, 'ol_source' or null if not found
     * @param {string} layerID
     */
    getLayerByID(layerID) {
        let layer = this._registered_layers.find((layer) => layer.id == layerID)
        if (!layer) {
            console.warn(
                `MAP_MANAGER.getLayer: Unable to locate registered layer with id '${layerID}'!`
            )
            return null
        }
        return layer
    },

    /**
     * Get the features of the layer associated with the passed layerid
     * @param {string} layerID
     */
    getLayerFeatures(layerID) {
        // get layer
        let layer = this.getLayerByID(layerID)
        if (!layer) {
            console.warn(
                `MAP_MANAGER.getFeatures: Unable to locate registered layer with id '${layerID}'!`
            )
            return null
        }
        //return layer.ol_source.getFeatures()
        return layer.features
    },

    /**
     * method to set the styling for features individually
     * @param {string} layerID
     * @param {Style|Style[]|Function} styling
     */
    setFeatureStyles(layerID, styling) {
        let features = this.getLayerFeatures(layerID)
        features.forEach((feature) => feature.ol_feature.setStyle(styling))
    },

    addInteraction() {},
    removeInteraction() {},

    removeLayerByID() {},
    setLayerVisibility() {},

    // HELPER METHODS
    currentCenter() {
        return this.map.getView().getCenter()
    },

    /**
     * convert a generic 'styling config' to a valid OL Styling config object that replaces specified keywords with constructors for Openlayers style objects
     * @param {any} config
     */
    _convertConfigToStyle(config) {
        // Mapping of specific keys to associated 'Openlayers Style Objects' which will become constructors
        let OBJ_MAPPING = {
            stroke: Stroke,
            fill: Fill,
            circle: Circle,
            shape: RegularShape,
            icon: Icon,
            text: Text,
        }

        // Mapping of expected key for the instances generated from the above style objects
        let KEY_MAPPING = {
            stroke: "stroke",
            fill: "fill",
            circle: "image",
            shape: "image",
            icon: "image",
            text: "text",
        }

        /**
         * Convert a passed generic style config to an OL styleconfig object (adds constructors)
         * NOTE: this worked first try. so obviously if something starts going wrong, it's most likely in here
         *
         * @param {any} FROM generic styling config to be converted
         */
        function convert(FROM) {
            if (!FROM) return null
            let ENTRIES = Object.entries(FROM).map(([key, value]) => {
                // if not an OBJECT type, just return it
                if (!isObject(value)) return [key, value]

                if (OBJ_MAPPING[key]) {
                    return [
                        KEY_MAPPING[key],
                        new OBJ_MAPPING[key](convert(value)),
                    ]
                } else {
                    return [key, value]
                }
            })

            return Object.fromEntries(ENTRIES)
        }

        return convert(config)
    },

    generate_style(config) {
        let CONVERTED = this._convertConfigToStyle(config)
        return new Style(CONVERTED)
    },

    // getSelectedFeatures() {
    //     this._interactions["select"].getFeatures()
    // },

    clearSelection() {
        this._interactions["select"].getFeatures().clear()
    },
}

export default MANAGER
