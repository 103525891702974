// mapping of FieldNames to display names
/* 
Timestamp	
Name of Organization	
Contact Name	
Contact Phone Number	
Phone Extension						
Hours of Operation (Open)	
Hours of Operation (Close) 	
Type of Assistance	
Number of People Assisted	
Address (Line 1)	
Address (Line 2)	
City	
State	
ZIP
*/
if(
    typeof(process.env.GOOGLE_API_KEY) == "undefined" ||
    typeof(process.env.GOOGLE_SHEET_ID) == "undefined" ||
    typeof(process.env.GOOGLE_SHEET_TAB) == "undefined"

){
  console.error(`Google API missing required environment definitions! 
    The following env variables are required: 
        GOOGLE_API_KEY 
        GOOGLE_SHEET_ID
        GOOGLE_SHEET_TAB`)  
}

let CONNECTION = {
    api_key: process.env.GOOGLE_API_KEY,
    sheet_id: process.env.GOOGLE_SHEET_ID,
    sheet_tab: process.env.GOOGLE_SHEET_TAB,
}


let FieldMapping = {
    "Timestamp": "",
    "Name of Organization": "Organization",
    "Contact Name": "",
    "Contact Phone Number": "Phone Number",
    "Phone Extension": "ext.",
    "Hours of Operation (Open)": "",
    "Hours of Operation (Close)": "(Close)",
    "Date of Service": "",
    "Type of Assistance": "",
    "Number of People Assisted": "",
    "Number of Volunteers": "",
    "Address (Line 1)": "",
    "Address (Line 2)": "",
    "City": "",
    "State": "",
    "ZIP": "",
    
}


function getDisplayName(fieldID) {
    return FieldMapping[fieldID] || fieldID
}

// mapping of tables to display in feature info
//  'Group name' : [ ... list of FieldNames to display in group ... ]
let DisplayGroups = {
    "Assisting Organizations": [
        "Name of Organization",
        "Contact Name",
        "Contact Phone Number",
        "Phone Extension",
        "Hours of Operation (Open)",
        "Hours of Operation (Close)",
        
    ],    
    "Provided Assistance": [
        "Timestamp",
        "Name of Organization",
        "Date of Service",
        "Type of Assistance",
        "Number of Volunteers",
        "Number of People Assisted",
    ],
}


export default {
    // FieldMapping,
    CONNECTION,
    getDisplayName,
    DisplayGroups
}