﻿/**
 *
 * This file is expected to include Page specific setup for the
 * setup for a specific view 'Index.cshtml' in this case
 *
 * General rule of thumb for custom components
 *      Default export is a manager for a custom object type
 *      Managers contain an 'OBJECTS' entry for Object class which can be used for instantiating them
 *
 *
 */
import "~/main.scss"

// import "dotenv"

import Axios from "axios"

import MOMENT from "moment"

import NOTIFY from "~/components/Notifications"
import MODAL from "~/components/Modal"
import CACHE_MANAGER from "~/components/Caching"
import GOOGLE_DATA_MANAGER from "~/components/GoogleAPI"
import HERE_DATA_MANAGER from "~/components/HereAPI"

// import map api
import {
    MAP_MANAGER,
    MAP_FEATURE_STATE_MANAGER as STATE_MANAGER,
    MAP_FEATURE_FILTER_MANAGER as FILTER_MANAGER,
} from "~/components/Map"

import DOM from "~/UTILS/dom"
// import { groupArrayBy } from "~/UTILS/object_utils"
import { GroupBy } from "./UTILS/object_utils"

// import configurations
import FEATURE_STATE_CONFIG from "~/config/FeatureStateConfig"
// import GOOGLE_API_CONFIG from "~/config/GoogleSheetConfig.DEV.js"
import GOOGLE_API_CONFIG from "~/config/GoogleSheetConfig.PROD.js"
import HERE_API_CONFIG from "~/config/HereConfig.js"


/******************************************************
 *  DEFAULT SETTINGS
 *
 ******************************************************/

// this is mostly just here for testing remove before publishing, (dont get dependant... it's unhealthy)
let GLOBALS = {
    GOOGLE_CONFIG: GOOGLE_API_CONFIG.CONNECTION,
    HERE_CONFIG: HERE_API_CONFIG,
    CACHE: null,
    GOOGLE_API: null,
    HERE_API: null,
    FEATURE_DATA: {},
    MAP_MANAGER,
    STATE_MANAGER,
    FILTER_MANAGER,
    FEATURE_STATE_CONFIG,
    GOOGLE_API_CONFIG,
    NOTIFY,
    
}

// push globals to the browser for debugging (NOT FOR PROD)
// window.GLOBALS = GLOBALS

let DEFAULTS = {
    FEATURE_ICON: "assets/svgs/house-flag.svg",
}

// Define custom Notification types

// NOTIFY.UpdateTypes("DEFAULT", {
//     selector: DOM.select("#map"),
// })
NOTIFY.UpdateTypes("success", {
    className: "success",
})
NOTIFY.UpdateTypes("error", {
    className: "error",
    close: true,
    duration: -1,
})

// define a custom modal types
MODAL.UpdateTypes("auth", {
    title: "Please specify password to view content",
    customClass: "CUSTOM_MODAL",
    allowOutsideClick: false,
    
    input: 'text',
    inputAttributes: {
        autocapitalize: 'off'
    },
//   showCancelButton: true,
//   confirmButtonText: 'Look up',
  showLoaderOnConfirm: true,
  preConfirm: (login) => {
    
    // console.log("PRECONFIRM", login)
    return Axios.post("/auth", {login})
        .then((res) => {
            console.log("CacheAPI: backend is running!")
            return true
        })
        .catch((err) => {
            console.warn(
                "CacheAPI: AUTH FAILED OR BACKEND NOT RUNNING! caching functionality will not work in this session!"
            )
            // throw new Error(err)
            console.log(err)
            return false
        })   
  },
  
})


MODAL.UpdateTypes("feature", {
    title: "Feature Information",
    customClass: "featureInfo CUSTOM_MODAL",
    showCloseButton: true,
})

// add states to the state manager
Object.values(FEATURE_STATE_CONFIG.STATES).forEach((state) =>
    STATE_MANAGER.addState(state)
)

/*****************************************************************
 * Feature Styling
 *****************************************************************/

function FeatureStyling(feature_obj) {

    // check for feature state hidden in filters before adding styles
    let feature = feature_obj.ref
    // console.log(feature_obj)
   


    let highest_severity = STATE_MANAGER.getState(
        STATE_MANAGER.highestPriority([
            // get all of the linked layers (severity in this case)
            // ...[
            //     ...feature.states
            //         .filter((state) => FILTER_MANAGER.getFilter(state).enabled)
            //         // .map((state) => LINKED_STATES[state]),
            // ],

            // also add the default state
            "DEFAULT_FEATURE",
        ])
    )

    let severity_color = highest_severity.getStyle().circle.fill.color
    // let severity_color = "green"

    
    let DRAWN_ICONS = 0
    let icon_styles = STATE_MANAGER.sortKeysByPriority(
        feature.states || []
    ).map((state, index) => {
        
        // console.log(state)
        // check if the state is hidden in filters before adding
        if (!FILTER_MANAGER.getFilter(state).enabled) return null
            
        

         // check if the date filter is enabled, and if so filter 
         if(GLOBALS.FILTER_MANAGER.getFilter('dateRangeFilter').enabled){
            let start_date = GLOBALS.FILTER_MANAGER.getFilter('dateRangeFilter').value[0]
            let end_date = GLOBALS.FILTER_MANAGER.getFilter('dateRangeFilter').value[1]
            
            let service_date = new Date(feature.serviceDate)

            let filterDefined = !!start_date && !!end_date
            let inRange = (start_date <= service_date && service_date <= end_date)
            if(filterDefined && !inRange) return null
            
            
        }

        let icon_size = 30
        let scale = 0.5

        let offsetY = icon_size * 0.5 - DRAWN_ICONS++ * icon_size * scale

        //let state_obj = STATE_MANAGER.getState(state)
        // let linked_color = STATE_MANAGER.getState(
        //     STATE_MANAGER.highestPriority(LINKED_STATES[state])
        // ).getStyle().circle.fill.color

        // NEED TO GRAB LINKED STATE COLOR FOR THIS ICON

        // return the expected state styling with defined overwrites
        //return MAP_MANAGER.generate_icon_style(FEATURE_STATE_CONFIG.STATES[state].getStyle({
        return MAP_MANAGER.generate_style(
            STATE_MANAGER.getState(state).getStyle({
                icon: {
                    // color: linked_color,
                    // color: STATE_MANAGER.getState(state).getStyle().circle.fill.color,
                    color: severity_color,
                    scale,
                    displacement: [-1 * icon_size, offsetY],
                },
            })
        )
    }).filter(icon => icon != null)

    let featureStyles = []

    // add highest linked prioity style
    if (highest_severity)
        featureStyles.push(
            MAP_MANAGER.generate_style(
                highest_severity.getStyle({
                    text: {
                        text: feature.name,
                        fill: { color: severity_color },
                    },
                })
            )
        )
    // add base feature style
    featureStyles.push(
        MAP_MANAGER.generate_style({
            icon: {
                src: DEFAULTS.FEATURE_ICON,
                color: "#fff",
                displacement: [0, 0],
                scale: 1,
            },
        })
    )

    // if no icons enabled set style to null
    // if(DRAWN_ICONS == 0) {
    if(icon_styles.length == 0) {
        feature_obj.ol_feature.setStyle(MAP_MANAGER.generate_style(null))
    }
    else {
        // add state styles
        featureStyles.push(...icon_styles)

        feature_obj.ol_feature.setStyle(featureStyles)
    }
    
}

/******************************************************
 *  FILTER Functionality
 *
 ******************************************************/
function EnabledFiltersCount(){
    return FILTER_MANAGER.getGroupFilters("FeatureStates").filter(f => f.enabled).length
}
function FILTER_CHANGED_EVT() {
    MAP_MANAGER.getLayerFeatures("Locations").forEach(FeatureStyling)
    
    // set the enabled filters counter
    FILTER_MANAGER.element.querySelector('.selection_counter').innerHTML = EnabledFiltersCount()
}

async function INIT_SiteCache() {
    GLOBALS.CACHE = new CACHE_MANAGER()

    await GLOBALS.CACHE.loadFileData()

    // console.log("loaded cache data:", GLOBALS.CACHE.getData)
}

async function INIT_GoogleAPI() {

    GLOBALS.GOOGLE_API = new GOOGLE_DATA_MANAGER({
        ...GLOBALS.GOOGLE_CONFIG,
        initial_fields: GLOBALS.CACHE.getData.GOOGLE_API_FIELDS || [],
        initial_data: GLOBALS.CACHE.getData.GOOGLE_API_DATA || [],
    })

    // await GLOBALS.GOOGLE_API.init()
}

async function INIT_HereAPI() {
    GLOBALS.HERE_API = new HERE_DATA_MANAGER({
        ...GLOBALS.HERE_CONFIG,
        initial_data: GLOBALS.CACHE.getData.HERE_API_DATA|| [],
    })
}

async function SYNC_GoogleAPI() {
    console.log("syncing sheet data for new entries not in cache")
    return await GLOBALS.GOOGLE_API.syncWithSheet()
}

async function SYNC_HereAPI() {
    /** sample google data
     * {
            "Timestamp": "1/31/2023 10:38:52",
            "multi-choice": "three",
            "decimal": "3.456",
            "text": "a third text answer",
            "Address (Line 1)": "635 Cajundome Blvd",
            "Address (Line 2)": "",
            "City": "Lafayette",
            "State": "LA",
            "ZIP": "70506"
        },
     */
    // if there's new data loaded, or HereAPI has less rows than GoogleAPI
    if (GLOBALS.HERE_API.numEntries < GLOBALS.GOOGLE_API.numEntries) {
        console.log("syncing HereAPI...")
        // debugger
        let non_synced_google_data = GLOBALS.GOOGLE_API.getData
            .slice(GLOBALS.HERE_API.numEntries)
            .map((row) => {
                return {
                    address1: row["Address (Line 1)"],
                    address2: row["Address (Line 2)"],
                    city: row["City"],
                    state: row["State"],
                    zip: row["ZIP"],
                }
            })

        console.log(
            `requesting ${non_synced_google_data.length} new HereAPI results`
        )
        // console.log(non_synced_google_data)

        await GLOBALS.HERE_API.syncWithNewData(non_synced_google_data)
    }
}

async function SYNC_RemoteData() {
    await SYNC_GoogleAPI()
    await SYNC_HereAPI()

    console.log("updating server cache")
    GLOBALS.CACHE.storeFileData({
        LAST_UPDATE: new Date(),
        GOOGLE_API_FIELDS: GLOBALS.GOOGLE_API.getFields,
        GOOGLE_API_DATA: GLOBALS.GOOGLE_API.getData,
        HERE_API_DATA: GLOBALS.HERE_API.getData,
    })

    console.log("Remote Sync Complete")
}

/******************************************************
 *  Data presentation
 *
 ******************************************************/

function FeatureSelection_EVT(event) {
    // console.log(event)
    let selectedFeatureID = event.selected[0].get("id")
    let $FeatureData = getFeatureDataHTML(
        // GLOBALS.MAP_MANAGER.getSelectedFeatures(selectedFeatureID)
        selectedFeatureID
    )

    // console.log(typeof $FeatureData.outerHTML)

    MODAL.ShowModal(
        {
            type: "feature",
            overrides: {
                html: `${$FeatureData.outerHTML}`,
                text: "?",
            },
        },
        (result) => {
            // console.log("in Modal Callback")
            MAP_MANAGER.clearSelection()
        }
    )
}

function GenerateDataTable(data = []) {
    if (!data.length) return

    let headers = Object.keys(data[0])

    let $table = DOM.createElement("table", {
        class: ["dataTable"],
    })

    // create header row
    let $headerRow = DOM.createElement("tr")

    headers.forEach((header) => {
        $headerRow.append(
            DOM.createElement("th", {
                text: GOOGLE_API_CONFIG.getDisplayName(header),
            })
        )
    })

    $table.append($headerRow)

    data.forEach((row) => {
        let $dataRow = DOM.createElement("tr")

        Object.values(row).forEach((value) => {
            $dataRow.append(DOM.createElement("td", { text: value }))
        })
        $table.append($dataRow)
    })

    return $table
}

function getFeatureDataHTML(featureID) {
    // console.log("selected feature: ", featureID)
    // console.log("feature data: ", GLOBALS.FEATURE_DATA[featureID])

    // create the structure
    let $wrapper = DOM.createElement("div", {
        class: "featureDataWrapper",
    })

    let $locationDataWrapper = DOM.createElement("div", {
        class: "locationDataWrapper",
    })

    let $sheetDataWrapper = DOM.createElement("div", {
        class: "sheetDataWrapper",
    })

    $wrapper.append($locationDataWrapper)
    $wrapper.append($sheetDataWrapper)

    // populate the location data (this should be the same for each so just use one)
    $locationDataWrapper.append(
        DOM.createElement("h3", {
            class: "locationData",
            text: featureID,
        })
    )

    // populate the sheet data
    let $sheetData = DOM.createElement("div", {
        class: "sheetData",
    })
    $sheetDataWrapper.append($sheetData)

    let groupedSheetData = GLOBALS.FEATURE_DATA[featureID].reduce(
        (accumulator, elem) => {
            // if (!accumulator["Info"]) accumulator["Info"] = []
            // if (!accumulator["Assistance"]) accumulator["Assistance"] = []

            // for each group iterate group fields
            Object.entries(GOOGLE_API_CONFIG.DisplayGroups).forEach(
                ([group, fields]) => {
                    if (!accumulator[group]) accumulator[group] = []

                    //  push an object requested fields to group collection
                    accumulator[group].push(
                        fields.reduce((accum_values, key) => {
                            accum_values
                            accum_values[key] = elem.SHEET_DATA[key]
                            return accum_values
                        }, {})
                    )
                }
            )
            return accumulator
        },
        {}
    )
    // console.log("grouped sheet data: ", groupedSheetData)

    Object.entries(groupedSheetData).forEach(([group, collection]) => {
        $sheetData.append(
            DOM.createElement("h3", {
                text: group,
            })
        )

        $sheetData.append(GenerateDataTable(collection))
    })

    return $wrapper
}

// typically the '$(document).ready(...)' can be replaced by the following,
//      document.addEventListener("DOMContentLoaded", function () { ... })
// but for ease of transition i've provided the following method
DOM.Document_Ready(async function () {

    // PROMPT FOR PASSWORD BEFORE INITIALIZING CACHE
    MODAL.ShowModal({type: "auth"}, (result) => {
        SUCCESSFUL_AUTH()
    })
    


    async function SUCCESSFUL_AUTH(){
        // initialize caching and api's for interaction with dynamic user notifications
        
        
        /************************************************* 
         * CACHE HANDLING
         *************************************************/
        console.log("Loading cache...")
        await NOTIFY.ShowDynamicNotification({
            call_fn: INIT_SiteCache,
            progress_text: "Initializing Site Cache...",

            // followup if successful
            success_type: "success",
            success_text: "Site Cache Initialized!",

            // followup if something goes wrong
            error_type: "error",
            error_text: "Failed to Initialize Site Cache!",
        })

        console.log("Loading GoogleAPI manager...")
        await NOTIFY.ShowDynamicNotification({
            call_fn: INIT_GoogleAPI,
            progress_text: "Initializing GoogleAPI...",

            // followup if successful
            success_type: "success",
            success_text: "GoogleAPI initialized!",

            // followup if something goes wrong
            error_type: "error",
            error_text: "Failed to Initialize GoogleAPI!",
        })

        console.log("Loading HereAPI manager...")
        await NOTIFY.ShowDynamicNotification({
            call_fn: INIT_HereAPI,
            progress_text: "Initializing HereAPI...",

            // followup if successful
            success_type: "success",
            success_text: "HereAPI initialized!",

            // followup if something goes wrong
            error_type: "error",
            error_text: "Failed to Initialize HereAPI!",
        })

        // check live google sheet fields for updates
        let sheetFieldsUpdated = await GLOBALS.GOOGLE_API.localFieldMismatch()

        console.log(
            "Mismatch between google sheet and cache fields: ",
            sheetFieldsUpdated
        )
        console.log("Cache date expired : ", GLOBALS.CACHE.requiresSync)

        if (GLOBALS.CACHE.requiresSync || sheetFieldsUpdated) {
            
            // update the cache by pulling latest new rows in the google sheet
            // and running THOSE new addresses through the 'Here' api
            await NOTIFY.ShowDynamicNotification({
                call_fn: SYNC_RemoteData,
                progress_text: "Performing Data Sync...",

                // followup if successful
                success_type: "success",
                success_text: "Data Synced!",

                // followup if something goes wrong
                error_type: "error",
                error_text: "Failed to Sync Data!",
            })
        }


        /************************************************* 
         * DATA/FILTERING 
         *************************************************/

        let serviceDates = GLOBALS.GOOGLE_API.getData.map((row) => new Date(row["Date of Service"]))
        let service_startDate = Math.min.apply(null,serviceDates)
        let service_endDate = Math.max.apply(null,serviceDates)
        
        // let service_startDate = MOMENT(Math.min.apply(null,serviceDates)).format("MM/DD/YYYY")
        // let service_endDate = MOMENT(Math.max.apply(null,serviceDates)).format("MM/DD/YYYY")
        
        FILTER_MANAGER.init({ target: "filters_container" })
        
        // console.log(service_startDate)
        // console.log(service_endDate)
        let DatePickerControl = new FILTER_MANAGER.OBJECTS.Filters.DateRangeFilter({
            id:"dateRangeFilter", 
            name:"Range Filter",
            enabled:false,
            
            minDate: service_startDate,
            maxDate: service_endDate,
            
            onValueChange: FILTER_CHANGED_EVT,
            onEnable: FILTER_CHANGED_EVT,
            onDisable: FILTER_CHANGED_EVT

        })

        // FILTER_MANAGER.addControl({
        //     element: DatePickerControl.element
        // })
        FILTER_MANAGER.addFilter(DatePickerControl)

        // add filter toggles for each of the feature states
        Object.entries(FEATURE_STATE_CONFIG.STATES).forEach(([id, state]) => {
            // let ignore = ['SEVERITY_NORMAL', 'SEVERITY_MINOR', 'SEVERITY_MODERATE', 'SEVERITY_URGENT', 'SEVERITY_EMERGENCY']
            // console.log(id, FEATURE_STATE_CONFIG.FILTERABLE.indexOf(id))
            if (FEATURE_STATE_CONFIG.FILTERABLE.indexOf(id) == -1) return
            // debugger;
            FILTER_MANAGER.addFilter(new FILTER_MANAGER.OBJECTS.Filters.StateFilter({
                id,
                group: "FeatureStates",
                icon: state.getStyle().icon.src,
                name: state.getLabel(),
                onChange: FILTER_CHANGED_EVT,
            }))
        })

        // generate a select all control for featureStates
        // add a select all button
        let selectionControl = DOM.createElement('div')

        let selectall_element = DOM.createElement("button", {
            class: ["selectAll", "filter_toggle"],            
        })


        let selection_counter_label= DOM.createElement('span', {class: 'selection_counter_label'})
        selection_counter_label.append('Selected:')

        let selection_counter= DOM.createElement('span', {class: 'selection_counter'})
        selection_counter_label.append(selection_counter)
        selection_counter.innerText = EnabledFiltersCount()

        selectall_element.append("Select/Deselect All")
        selectall_element.addEventListener("click", (e) => {
            
            let filters = FILTER_MANAGER.getGroupFilters('FeatureStates')
            let allOn = filters.every(filter => filter.enabled)

            if(allOn){
                filters.forEach(filter => {
                    filter.Toggle(false)
                });
            }else{
                filters.forEach(filter => {
                    filter.Toggle(true)
                });
            }
        })

        selectionControl.append(selectall_element,selection_counter_label)
        FILTER_MANAGER.addControl({
            group: "FeatureStates",
            element: selectionControl,

        })

        // generate a merged collection of 'Addresses' to assistance types
        let MergedData = GLOBALS.GOOGLE_API.getData.map((gData, index) => {
            return {
                SHEET_DATA: gData,
                LOCATION_DATA: GLOBALS.HERE_API.getData[index],
            }
        })
        GLOBALS.FEATURE_DATA = GroupBy(
            MergedData,
            (elem) => elem.LOCATION_DATA.title
        )

        // console.log("grouped addresses", GroupedFeatureCollection)



        /************************************************* 
         * INTERFACE INITIALIZATION
         *************************************************/

        MAP_MANAGER.init({ target: "map", onSelect: FeatureSelection_EVT })

        // TODO: Figure out a way to kickout locations that failed Here query

        //create a feature for each unique address, mapping each 'Type of Assistance' in the collection as a state
        MAP_MANAGER.addLayer(
            "Locations",
            Object.entries(GLOBALS.FEATURE_DATA).map(([address, collection]) => {
                let coords = [
                    collection[0].LOCATION_DATA.position.lng,
                    collection[0].LOCATION_DATA.position.lat,
                ]
                let states = collection.reduce((states_collection, entry) => {
                    states_collection.push(entry.SHEET_DATA[["Type of Assistance"]])
                    // states_collection.push(entry.SHEET_DATA[['Number of People Assisted']])
                    return states_collection
                }, [])

                let serviceDate = collection[0].SHEET_DATA["Date of Service"]

                // console.log('adding feature: ', address, coords, states)

                return {
                    name: address,
                    serviceDate,
                    states,
                    coords,
                }
            })
        )

        MAP_MANAGER.getLayerFeatures("Locations").forEach(FeatureStyling)
    }

    

    // *********************************** BUTTON EVENTS ***********************************
    // document.querySelectorAll("#refresh").forEach((el) => {
    //     el.addEventListener("click", (evt) => {
    //         PROTOTYPE_SAMPLE.UpdateFeatureData()

    //         MAP_MANAGER.getLayerFeatures("Facilities").forEach(FeatureStyling)
    //     })
    // })

    // document.querySelectorAll("#reset").forEach((el) => {
    //     el.addEventListener("click", (evt) => {
    //         MAP_MANAGER.resetView()
    //     })
    // })

    // create the map and it's filter container
})
