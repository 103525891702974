/**
 * Manager to handle the import of google sheets data, parse it,
 * and make it available to the rest of the application
 *
 * Google's Request limits are defined as:
 *      50,000 requests per project per day (as of 3/22/23)
 *
 *
 */

// NOTE: if this throws an import error, add "browser": { "fs": false } to 'package.json' (webpack bug)
// import FILE_SYSTEM from 'fs'
// const FILESYS = require('fs')
import axios from "axios"

import { ArraysEqual } from "~/UTILS/object_utils"
// import * as FILESYS from 'fs'

// window.FILESYS = FILESYS

// const { google } = require("googleapis")
// const sheets = google.sheets("v4")

class MANAGER {
    OBJECTS = {}

    constructor({
        api_key,
        sheet_id,
        sheet_tab,
        initial_data = [],
        initial_fields = [],
    }) {
        // console.log("Created new GoogleAPI manager")

        this._api_key = api_key

        if (!sheet_id || !sheet_tab) {
            console.error(
                `GoogleAPI: Could not initialize GoogleAPI without specifying 'sheet_id' and 'sheet_tab'!`
            )
            return
        }

        this._sheet_id = sheet_id
        this._sheet_tab = encodeURIComponent(sheet_tab)

        this._LIVE_SHEET_FIELDS = null
        this._FIELDS = initial_fields
        this._DATA = initial_data

        // this._init()
    }

    async init() {}

    async _fetchLiveHeader() {
        let header_response = await this._fetchHeader()
        this._LIVE_SHEET_FIELDS = header_response.data.values[0].map(value => value.trim())
    }

    async localFieldMismatch() {
        if (!this._LIVE_SHEET_FIELDS) await this._fetchLiveHeader()

        // this._FIELDS.sort()
        // this._LIVE_SHEET_FIELDS.sort()

        return !ArraysEqual(this._FIELDS, this._LIVE_SHEET_FIELDS)
    }

    /**
     * Get any new rows from this managers associated google sheet. grabs any rows past known parsed rows
     * @returns boolean representing if new data was found during sync
     */
    async syncWithSheet() {
        // fetch the header row of the sheet for mapping purposes
        // let header_response = await this._fetchHeader()
        // this._FIELDS = header_response.data.values[0]

        let fullRefresh = await this.localFieldMismatch()
        if (fullRefresh) {
            console.log(
                "GoogleAPI: Full refresh required due to cache field mismatch"
            )
            this._DATA = []
        }

        this._FIELDS = this._LIVE_SHEET_FIELDS // this should be populated from before

        let skip_rows = fullRefresh ? 0 : this._DATA.length

        // console.log(
        //     `GoogleAPI: preloaded ${this._DATA.length} rows from cache, fetching updated data from sheet.`
        // )

        let newRawData = await this._fetchRawData(skip_rows)

        // if(!newRawData.data.values){
        //     console.log(`GoogleAPI: No new rows found`)
        //     return false
        // }

        // console.log(
        //     `GoogleAPI: fetched ${newRawData.data.values.length} new rows, formatting raw data for cache`
        // )
        let formatted_data = this._formatFetchedData(newRawData.data.values)

        this._DATA.push(...formatted_data)

        // sort by timestamp to be safe (should be in order, but never know)
        //this._DATA.sort((a, b) =>  new Date(a.Timestamp) - new Date(b.Timestamp))

        return true
    }

    async _fetchHeader() {
        try {
            // console.log("GoogleAPI:Fetching header row...")
            return await axios.get(
                `https://sheets.googleapis.com/v4/spreadsheets/${
                    this._sheet_id
                }/values/${this._sheet_tab}${encodeURIComponent(
                    `!A1:Z1`
                )}?alt=json&key=${this._api_key}`
            )
        } catch (err) {
            console.warn(
                "GoogleAPI._fetchHeader: there was an error with the request"
            )
            console.log(err)
        }
    }

    async _fetchRawData(skip_rows = 0) {
        // this needs to be set to the last known row captured (1-based)

        try {
            let URI_range =
                skip_rows == null
                    ? ""
                    : encodeURIComponent(`!A${skip_rows + 2}:Z`)

            // console.log(
            //     `fetching: https://sheets.googleapis.com/v4/spreadsheets/${this._sheet_id}/values/${this._sheet_tab}${URI_range}?alt=json&key=${this._api_key}`
            // )

            // get the updated value ranges

            return await axios.get(
                `https://sheets.googleapis.com/v4/spreadsheets/${this._sheet_id}/values/${this._sheet_tab}${URI_range}?alt=json&key=${this._api_key}`
                // `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values:batchGet?${}A:I?alt=json&key=${this._api_key}`
            )
        } catch (err) {
            console.warn(
                "GoogleAPI._fetchData: there was an error with the request"
            )
            console.log(err)
        }
    }

    _formatFetchedData(rawData = []) {
        // format the raw data rows into objects mapping header row names to values
        return rawData.map((row) => {
            // create an object for each row
            return Object.fromEntries(
                // map header row cell values to row cell values (parallel lists) ()
                this._FIELDS.map((key, index) => {
                    return [key, row[index] || ""]
                })

                // row.map((cell, index) => {
                //     return [this._FIELDS[index], cell]
                // })
            )
        })
    }

    get getData() {
        return this._DATA
    }

    get getFields() {
        return this._FIELDS
    }

    get numEntries() {
        return this._DATA.length
    }
    get getLatestEntryTime() {
        if (!this._DATA.length) return null

        return new Date(this._DATA.at(-1).Timestamp)
    }
}

export default MANAGER
