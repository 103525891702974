import METHODS from "./methods"
import DOM from "~/UTILS/dom"

// create a toggleable element to limit a dataset
export class FilterObj {
    constructor({
        id,
        name = "FILTER",
        enabled = true,
        group = "global",
        onEnable,
        onDisable,
        onChange,
    }) {
        //let requiredConfigs = ['id']

        //if (requiredConfigs.some(key => !Object.keys(config).includes(key))) {
        //    console.error(`Filter: The passed config is missing a required value! Config must contain values for the following: ${requiredConfigs.join(', ')}`)
        //    return;
        //}

        this._id = id        
        this._name = name        
        this._enabled = enabled
        this._group = group

        this._onEnable = onEnable
        this._onDisable = onDisable
        this._onChange = onChange

        this._generateDOM()
        
    }

    _generateDOM() {
        /**
         *  generate template of the following
         *      filter
         *          checkbox
         *          icon
         *          name
         *
         */

        let filter_element = DOM.createElement("div", {
            class: ["filter"],
            data: {
                filterId: this._id,
            },
        })

        // TODO: NOT SUPER HAPPY WITH THIS IMPLEMENTATION
        this.$toggle = DOM.createElement("div", {
            class: ["filter_toggle"],
            //data: {

            //}
        })
        

        this.$toggle.append(
            DOM.createElement("img", {
                class: ["filter_toggle_icon"],
                src: "assets/svgs/check-solid.svg",

                //data: {

                //}
            })
        )

        this.$toggle.addEventListener("click", (e) => {
            this.Toggle()
        })

        filter_element.append(this.$toggle)

        this.$body = DOM.createElement("div", {
            class: ["filter_body_wrapper"],
            //data: {

            //}
        })

        filter_element.append(this.$body)

        if (this._enabled) filter_element.classList.add("active")

        this.$element =  filter_element
    }

    get element() {
        return this.$element
    }
    get enabled() {
        return this._enabled
    }

    
    get value() {
        return this._id
    }

    get group() {
        return this._group
    }

    Toggle(set_to) {
        

        let newState = (typeof(set_to) != "undefined")? set_to: !this._enabled        
        if(newState) this.Enable(); else this.Disable()
    }

    /*
        TODO: Need a way to fire an event here 
     
     */

    Enable() {
        this._enabled = true
        this.$element.classList.add("active")
        
        if (this._onEnable) {
            this._onEnable.apply(this, [this])
        }

        this._onChange_evt()
    }

    Disable() {
        this._enabled = false
        this.$element.classList.remove("active")

        if (this._onDisable) {
            this._onDisable.apply(this, [this])
        }

        this._onChange_evt()
    }



    _onChange_evt() {
        if (this._onChange) {
            this._onChange.apply(this, [this])
        }
    }
}


export default {
    FilterObj,
    ...METHODS
}