/**
 * Middleware component for handling 'Toastify' notifications in the application
 * provides methods of defining reusable config types
 */
import Toast from "toastify-js"
import "toastify-js/src/toastify.css"
import "./Notifications.scss"

export let TYPES = {
    DEFAULT: {
        position: "left",
        duration: 3000,
        offset: {
            x: 30,
            y: 45,
        },
    },
}

export function UpdateTypes(key, config, extend_default = true) {
    TYPES[key] = { ...(extend_default ? TYPES.DEFAULT : {}), ...config }
}

/**
 *
 * @param {*} param0
 */
export function ShowNotification({ text, type = "DEFAULT", overrides } = {}) {
    if (Object.keys(TYPES).indexOf(type) == -1) {
        console.warn(`
        Notifications: Attempted to show a notification of undefined type: '${type}', reverting to default notification styles
            to define this type import 'TYPES' and define it with  TYPES.update(name, {...})
    `)
        type = "DEFAULT"
    }

    Toast({
        // ...DEFAULT_TOAST_CONFIG,

        ...TYPES[type],
        ...overrides,
        text,
    }).showToast()
}

export async function ShowDynamicNotification({
    call_fn,
    progress_text,
    progress_type = "DEFAULT",
    progress_overrides,
    success_text,
    success_type = "DEFAULT",
    success_overrides,
    error_text,
    error_type = "DEFAULT",
    error_overrides,
}) {
    if (progress_text)
        ShowNotification({
            text: progress_text,
            type: progress_type,
            overrides: progress_overrides,
        })

    try {
        await call_fn.apply()

        if (success_text)
            ShowNotification({
                text: success_text,
                type: success_type,
                overrides: success_overrides,
            })
    } catch (err) {
        if (error_text)
            ShowNotification({
                text: error_text,
                type: error_type,
                overrides: error_overrides,
            })
    }
}

export default {
    TYPES,
    UpdateTypes,
    ShowNotification,
    ShowDynamicNotification,
}
